@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/munich");
@import url("https://fonts.cdnfonts.com/css/apercu-pro");
@import url("https://fonts.cdnfonts.com/css/kayak-sans");
@import "~boxicons/css/boxicons.min.css";

*,
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Kayak Sans", sans-serif;
  overflow-x: hidden;
}

.munich {
  font-family: "Apercu Pro", sans-serif;
  font-weight: bold;
}
