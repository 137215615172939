.main_titles {
  font-style: italic;
  background-image: linear-gradient(45deg, #a855f7, #6366f1);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  font-size: 3em;
  letter-spacing: 0.1em;
}

.main_titles.non_styled {
  font-weight: 400;
  letter-spacing: normal;
}

.title_holder {
  background-image: linear-gradient(45deg, #a855f7, #6366f1);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 1000;
  font-size: clamp(4em, 1em + 6vw, 8em);
}

.transitioner {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.8s ease;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 10px;
}

.transitioner.open {
  visibility: visible;
  height: 100dvh;
  top: 50%;
}

@media (width >= 768px) {
  .skill_card {
    transition: cubic-bezier(0, 1.99, 1, 0.6) 0.5s;
  }

  .skill_card:hover {
    width: 250px;
    max-height: 350px;
  }

  .skillslist:has(.skill_card:hover) .skill_card:not(:hover) {
    opacity: 0.2 !important;
  }

  .parallax_html {
    animation: parallax linear;
    animation-timeline: scroll();

    z-index: 10;
    --parallax-speed: 10;
    --parallax-dir: -200px;
  }

  .parallax_html .parallax_html_block {
    animation: parallax linear;
    animation-timeline: scroll();
    --parallax-dir: -200px;
  }
  .parallax_html .parallax_html_block:nth-child(1) {
    --parallax-speed: 5;
  }
  .parallax_html .parallax_html_block:nth-child(3) {
    --parallax-speed: 9;
  }
  .parallax_html .parallax_html_block:nth-child(6) {
    --parallax-speed: 2;
  }
  .parallax_html .parallax_html_block:nth-child(8) {
    --parallax-speed: 12;
  }
  .parallax_html .parallax_html_block:nth-child(2) {
    --parallax-speed: 10;
  }
  .parallax_html .parallax_html_block:nth-child(4) {
    --parallax-speed: 5;
  }

  .parallax_css {
    animation: parallax linear;
    animation-timeline: scroll();
    --parallax-speed: 5;
    --parallax-dir: 200px;
  }

  @keyframes parallax {
    to {
      transform: translateY(calc(var(--parallax-speed) * var(--parallax-dir)));
    }
  }
}
@media (width < 768px) {
  .init-anim {
    height: 0px;
    overflow: hidden;
    animation: openMenu ease-in-out 0.3s forwards;
  }

  @keyframes openMenu {
    0% {
      height: 0px;
    }
    100% {
      height: 250px;
    }
  }
}
